import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";
import "../style/brands.less";

const BrandsIndex = ({ data }) => {
    const brands = data.allMarkdownRemark.edges;

    return (
        <Layout>
            <center>
                <h1>South Napa Vintners Collection of Wines</h1>
                <div style={{marginTop: 50, marginBottom: 50}}>
                    The South Napa Vintners collection are luxury wines, all vinted and bottled in the Napa Valley. Classic wines start in the vineyard, and we rely on our relationships that go back decades (and generations!) with the premier growers and wineries throughout California’s North Coast.
                </div>
            </center>
            <ul className="brands-list">
                {brands.map(({ node }, index) => (
                    <li
                        key={node.id}
                        className={`brand-item ${index % 2 === 1 && 'lightGrayBackground'}`} // Alternate classes
                    >
                        <div className={`brand-content ${index % 2 === 1 && 'right'}`}>
                            <div className="title-image-container">
                                <Link to={node.frontmatter.website}>
                                    <h2 className="brand-title">{node.frontmatter.title}</h2>
                                </Link>
                                <div className="brand-image-container">
                                    <img src={node.frontmatter.image.publicURL} alt={node.frontmatter.title} />
                                </div>
                            </div>
                            <div className="description-container">
                                <div className="brand-description">
                                    {node.frontmatter.description ? (
                                        <ReactMarkdown>{node.frontmatter.description}</ReactMarkdown>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </Layout>
    )
}

export default BrandsIndex

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/brands/" } }
      sort: { fields: [frontmatter___order, frontmatter___date], order: [ASC, DESC] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            image {
              publicURL
            }
            website
            order
          }
          html
        }
      }
    }
  }
`
